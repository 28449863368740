<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Productmanagercommunicationproject" style="width:100vw">
  <div id="abdc754c">
    沟通中项目
  </div>
  <div id="aca2079d">
    <div id="ad3debce">
      <div id="af6bbacb">
        沟通中项目数
      </div>
      <div id="a87e5880">
        30
      </div>
    </div>
    <div id="a433730e">
      <div id="a1b5aab2">
        当月新增沟通客户数
      </div>
      <div id="a8514ff8">
        6
      </div>
    </div>
  </div>
  <div id="ae79564b">
    沟通用户转化率曲线
  </div>
  <img src="../assets/img-fed97bee.png" id="fed97bee"/>
  <div id="ae30fbda">
    沟通中客户列表
  </div>
  <div id="da6f03c7">
    <dal-comp-talk id="cmpda6f03c7">
    </dal-comp-talk>
  </div>
  <el-pagination id="e5cc7b6a">
  </el-pagination>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_comp_talk from '../components/dal-comp-talk'
export default{
  components:{
    'dal-comp-talk':dal_comp_talk
  },
  data(){
    return{
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#abdc754c{
  padding-top:100px;
}
#abdc754c{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#aca2079d{
  width:486px;
  position:relative;
  margin-top:30px;
  padding-left:163px;
}
#ad3debce{
  width:203px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#ad3debce{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#af6bbacb{
  padding-top:20px;
}
#af6bbacb{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#a87e5880{
  margin-top:18px;
  padding-bottom:23px;
}
#a87e5880{
  text-align:center;
  color:#141620;
  font-size:27px;
  font-family:PingFang SC;
}
#a433730e{
  width:203px;
  position:relative;
  margin-left:80px;
  display:inline-block;
  vertical-align: middle;
}
#a433730e{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a1b5aab2{
  padding-top:20px;
}
#a1b5aab2{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#a8514ff8{
  margin-top:18px;
  padding-bottom:23px;
}
#a8514ff8{
  text-align:center;
  color:#141620;
  font-size:27px;
  font-family:PingFang SC;
}
#ae79564b{
  margin-top:60px;
}
#ae79564b{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#fed97bee{
  width:1322px;
  height:518px;
  margin-top:30px;
  padding-left:163px;
}
#fed97bee{
  vertical-align:middle;
}
#ae30fbda{
  margin-top:60px;
}
#ae30fbda{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#da6f03c7{
  width:1322px;
  margin-top:30px;
  padding-left:163px;
}
#e5cc7b6a{
  width:302px;
  margin-top:60px;
  padding-left:673px;
  padding-bottom:52px;
}
/*在此注释下方添加自定义样式*/
</style>
